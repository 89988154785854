import React, { useEffect, useState, useContext } from 'react';
import dayjs from 'dayjs';
import * as styles from '../../../styles/admin/StudyActivity.module.scss';
import AdminContext from '../../../context/Admin/AdminContext';
import { fetchMetrics } from '../Services/MetricsApiService';

/**
 *
 *
 * @return {*}
 */
function StudyActivity() {
  const { companyInfo } = useContext(AdminContext);
  const [studyActivity, setStudyActivity] = useState(null);

  const fetchStudyActivity = async () => {
    const enterpriseId = companyInfo?.id;
    const path = `/enterpriseperformancestats/${enterpriseId}`;
    const from = dayjs().subtract(1, 'week').toISOString();
    const to = dayjs().toISOString();
    try {
      const response = await fetchMetrics(path, {
        from,
        to,
      });
      setStudyActivity(response?.data?.attributes);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (companyInfo?.id) {
      fetchStudyActivity();
    }
  }, [companyInfo]);

  return (
    <div className={styles.studyActivity}>
      <div className={styles.studyActivityHeader}>
        <h4>Study Activity - Current Week Statistics</h4>
      </div>
      <div className={styles.studyActivityBody}>
        <div className={styles.activityData}>
          <div className={styles.value}>
            {studyActivity?.coursesEnrolled}
          </div>
          <div className={styles.title}>
            New courses enrolled
          </div>
        </div>
        <div className={styles.activityData}>
          <div className={styles.value}>
            {studyActivity?.contentConsumptionHours}
            <span className={styles.title}> hrs</span>
          </div>
          <div className={styles.title}>
            Content consumed
          </div>
        </div>
        <div className={styles.activityData}>
          <div className={styles.value}>
            {studyActivity?.assignmentsCompleted}
          </div>
          <div className={styles.title}>
            Assignments completed
          </div>
        </div>
        <div className={styles.activityData}>
          <div className={styles.value}>
            {studyActivity?.coursesCompleted}
          </div>
          <div className={styles.title}>
            Courses completed
          </div>
        </div>
      </div>
    </div>
  );
}

export default StudyActivity;
