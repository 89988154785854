import React, {
  useState, useEffect, useContext,
} from 'react';
import { Spin } from 'antd';
import { Role } from '../../../models/index';
import SidebarComponent from '../Sidebar';
import HeaderComponent from '../Header';
import * as styles from '../../../styles/admin/Overview.module.scss';
import StudyActivity from './StudyActivity';
import Onboarding from './Onboarding';
import AdminContext from '../../../context/Admin/AdminContext';
import { fetchAllInvitedUsers } from '../Accounts/Services/InviteTabService';
import { getUsersByEnterpriseID } from '../../GraphQL/Services';
import { getCurrentUser } from '../../Shared/Services/AuthService';
import BarChart from '../../Shared/BarChart';
import { fetchMetrics } from '../Services/MetricsApiService';

type Props = {
  identityProviderClient: any
}

export interface OnboardingProgressType {
  isUserInvited: boolean,
  loading: boolean,
}
export interface ChartDataType {
  labels:(string | number)[][],
  datasets: {
    data: string[],
    backgroundColor: string,
    borderWidth: number
  }[]
}

interface ChartAttributes {
  usersCompletedAssignment:number,
  usersCompletedCourse: number,
  usersEnrolledCourse: number,
  usersInvited: number,
  usersJoined: number,
  usersWatchedLesson: number,
}

const defaultState = {
  isUserInvited: false,
  loading: true,
};

const Overview:React.FC<Props> = ({ identityProviderClient }) => {
  const {
    companyInfo, profileInfo, userData,
  } = useContext(AdminContext);
  const [isloading, setIsLoading] = useState(false);
  const [onboardingTasksProgress, setOnboardingTasksProgress] = useState<
  OnboardingProgressType>(defaultState);
  const [isEligibleForOnboarding, setIsEligibleForOnboarding] = useState(false);
  const [chartData, setChartData] = useState<ChartDataType | null>(null);
  const [showChartData, setShowChartData] = useState(false);

  const prepareChartData = (chartAttributes:ChartAttributes) => {
    if (chartAttributes?.usersInvited) {
      const usersInvited = chartAttributes?.usersInvited;
      const usersJoined = chartAttributes?.usersJoined;
      const enrolledCourses = chartAttributes?.usersEnrolledCourse;
      const watchedLessons = chartAttributes?.usersWatchedLesson;
      const assignmentsCompleted = chartAttributes?.usersCompletedAssignment;
      const completedCourses = chartAttributes?.usersCompletedCourse;

      if ([usersInvited, usersJoined, enrolledCourses, watchedLessons,
        assignmentsCompleted, completedCourses].some((attribute) => attribute > 1)) {
        setShowChartData(true);
      }

      const formattedData = [
        '100',
        `${Math.floor((usersJoined / usersInvited) * 100).toFixed(0)}`,
        `${Math.floor((enrolledCourses / usersInvited) * 100).toFixed(0)}`,
        `${Math.floor((watchedLessons / usersInvited) * 100).toFixed(0)}`,
        `${Math.floor((assignmentsCompleted / usersInvited) * 100).toFixed(0)}`,
        `${Math.floor((completedCourses / usersInvited) * 100).toFixed(0)}`,
      ];

      const data = {
        labels: [
          [usersInvited, 'Invitations sent'],
          [usersJoined, 'Users joined'],
          [enrolledCourses, 'Enrolled courses'],
          [watchedLessons, 'Lessons Watched'],
          [assignmentsCompleted, 'Assignments Completed'],
          [completedCourses, 'Completed courses'],
        ],
        datasets: [{
          data: formattedData,
          backgroundColor: 'rgba(156, 106, 249, 0.5)',
          borderWidth: 1,
        }],
      };
      setChartData(data);
    }
    setIsLoading(false);
  };

  const fetchChartAttributes = async () => {
    setIsLoading(true);
    const enterpriseId = profileInfo?.enterpriseID;
    const path = `/enterpriseperformancefunnel/${enterpriseId}`;
    try {
      const response = await fetchMetrics(path, {});
      prepareChartData(response?.data?.attributes);
    } catch (error) {
      console.log(error);
    }
  };

  const isUserInvited = async () => {
    const enterpriseId = profileInfo?.enterpriseID;
    const { totalInvitations } = await fetchAllInvitedUsers(enterpriseId);
    if (totalInvitations?.length > 0) return true;
    return false;
  };

  const isUserJoined = async () => {
    const currentUser = await getCurrentUser();
    const currentUserSub = currentUser.attributes.sub;
    const allUsersList = await getUsersByEnterpriseID(companyInfo?.id);
    const filteredUsers = allUsersList.filter((user) => {
      if (user?.deletedAt) return false;
      if (user?.sub === currentUserSub) return false;
      return user;
    });
    return filteredUsers?.length > 0;
  };

  const checkOnboardingTasksProgress = async () => {
    const userJoined = await isUserJoined();
    const userInvited = userJoined || await isUserInvited();
    setOnboardingTasksProgress({
      isUserInvited: userInvited,
      loading: false,
    });
  };

  const renderOnboarding = () => {
    if (!isloading) {
      if (isEligibleForOnboarding) {
        if (!onboardingTasksProgress?.loading) {
          return true;
        }
      }
    }
    return false;
  };
  const renderBarChart = () => {
    if (!isloading) {
      if (!onboardingTasksProgress?.loading) {
        if (chartData && showChartData) {
          return true;
        }
      }
    }
    return false;
  };

  const renderStudyActivity = () => {
    if (!isloading) {
      if (!onboardingTasksProgress?.loading) {
        if (showChartData) {
          return true;
        }
      }
    }
    return false;
  };

  useEffect(() => {
    if (identityProviderClient && profileInfo?.id) {
      fetchChartAttributes();
    }
  }, [identityProviderClient, profileInfo]);

  useEffect(() => {
    if (companyInfo?.id && profileInfo?.id && userData?.sub) {
      const userRole = profileInfo?.roles?.[0];
      if (userRole === Role.OWNER || userRole === Role.ADMIN) {
        setIsEligibleForOnboarding(true);
        checkOnboardingTasksProgress();
      } else {
        setOnboardingTasksProgress((prevState) => ({
          ...prevState,
          loading: false,
        }));
      }
    }
  }, [companyInfo, profileInfo, userData]);

  return (
    <div className={styles.overviewPageWrapper}>
      <HeaderComponent />
      <div className={styles.overviewContainer}>
        <SidebarComponent />
        <div className={styles.overviewWrapper}>
          <div className={styles.titleWrapper}>
            <p className={styles.titleDescription}>Welcome to your admin centre,</p>
            <p>{companyInfo?.name}</p>
          </div>
          <div className={styles.onboardingFunnel}>
            {renderOnboarding()
                && (
                <Onboarding
                  identityProviderClient={identityProviderClient}
                  onboardingTasksProgress={onboardingTasksProgress}
                  setOnboardingTasksProgress={setOnboardingTasksProgress}
                />
                )}
            {renderBarChart()
               && (
               <>
                 <h1 className={styles.funnelHeading}>
                   Learner Progress Funnel - Overall Statistics
                 </h1>
                 <BarChart chartData={chartData} />
               </>
               )}
            {
              (isloading || onboardingTasksProgress?.loading)
              && <Spin size="large" />
            }
          </div>
          {renderStudyActivity()
            && <StudyActivity />}
        </div>
      </div>
    </div>
  );
};

export default Overview;
