/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { Collapse } from 'antd';
import React, { useState, useContext, useEffect } from 'react';
import { API } from 'aws-amplify';
import dayjs from 'dayjs';
import { v4 as uuidv4 } from 'uuid';
import AdminContext from '../../../context/Admin/AdminContext';
import * as styles from '../../../styles/admin/Onboarding.module.scss';
import AddUser from '../Accounts/Popups/AddUser';
import ImportedUserProgress from '../Accounts/Popups/ImportedUserProgress';
import { OnboardingProgressType } from '.';
import { navigateTo, NavigationRoute } from '../Services/NavigationService';
import {
  getAppVersion, getHeaders, toaster,
} from '../../../services/utils';
import { fetchAllInvitedUsers } from '../Accounts/Services/InviteTabService';
import licenseCount from '../Accounts/Services/GetLicenseData';

const { Panel } = Collapse;

interface Props {
  identityProviderClient: any,
  onboardingTasksProgress: OnboardingProgressType,
  setOnboardingTasksProgress: React.Dispatch<React.SetStateAction<OnboardingProgressType>>
}

enum ModalVisibility {
  DEFAULT,
  ADD_USER,
  IMPORTED_USER,
}

const Onboarding:React.FC<Props> = ({
  identityProviderClient,
  onboardingTasksProgress,
  setOnboardingTasksProgress,
}) => {
  const { companyInfo, profileInfo } = useContext(AdminContext);
  const [modalVisibility, setModalVisibility] = useState(ModalVisibility.DEFAULT);
  const [licenseCountObject, setLicenseCountObject] = useState({});
  const [fileDataArray, setFileDataArray] = useState<any>([]);

  const triggerEvent = async (response:any) => {
    try {
      const eventApiName = 'enterpriseappevents';
      const eventPath = '/events';
      const headers = await getHeaders();
      const eventPromises = response.map((data:any) => {
        const payload = {
          event: 'Invite Sent',
          context: {
            app: {
              version: getAppVersion(),
            },
          },
          id: uuidv4(),
          userId: profileInfo?.id,
          enterpriseId: profileInfo?.enterpriseID,
          originalTimestamp: dayjs().toISOString(),
          sentAt: dayjs().toISOString(),
          properties: {
            invitationId: data?.id,
            accountName: companyInfo?.name,
          },
        };
        return API.post(eventApiName, eventPath, {
          headers,
          body: payload,
        });
      });
      await Promise.all(eventPromises);
    } catch (error) {
      console.log(error);
    }
  };

  const sendInvite = async (selectedEmails:string[]) => {
    try {
      if (selectedEmails?.length) {
        const apiName = 'enterpriseappinviteuser';
        const path = '/invite';
        const headers = await getHeaders();
        const promises = selectedEmails.map((email: string) => (
          API.post(apiName, path, {
            headers,
            body: {
              email: email.trim(),
              status: 'REQUESTED',
              inviter_id: profileInfo?.id,
              inviter_enterprise_id: companyInfo?.id,
            },
          })
        ));
        const responses = await Promise.allSettled(promises);

        const succeededApiCalls: any = [];

        responses.forEach((response) => {
          if (response.status === 'fulfilled') {
            succeededApiCalls.push(response.value);
          }
        });

        triggerEvent(succeededApiCalls);
        setOnboardingTasksProgress((prevState) => ({
          ...prevState,
          isUserInvited: true,
        }));

        if (responses.length !== succeededApiCalls.length) {
          toaster('Error in sending invite', 'error');
        } else {
          toaster('Invite sent successfully');
        }
      }
    } catch (error) {
      toaster('Something went wrong', 'error');
    }
  };

  const jsonToCSV = (csvData:Array<any>) => {
    const items = csvData;
    const header = Object.keys(items[0]);
    const csv = [
      header.join(','), // header row first
      ...items.map((row) => header.map((fieldName) => (row[fieldName])).join(',')),
    ].join('\r\n');
    return csv;
  };

  const passUploadedArrayFromFile = (users:any) => {
    setFileDataArray([...users]);
  };

  const setIsImportStatusModalVisible = (value:boolean) => {
    if (value) {
      setModalVisibility(ModalVisibility.IMPORTED_USER);
    }
  };

  const closeImportedUserProgressModal = async () => {
    setModalVisibility(ModalVisibility.DEFAULT);
    setOnboardingTasksProgress((prevState) => ({
      ...prevState,
      loading: true,
    }));

    try {
      const { pendingInvitations } = await fetchAllInvitedUsers(companyInfo?.id);

      if (pendingInvitations?.length) {
        setOnboardingTasksProgress((prevState) => ({
          ...prevState,
          isUserInvited: true,
        }));
      }
    } catch (error) {
      console.log(error);
    }

    setOnboardingTasksProgress((prevState) => ({
      ...prevState,
      loading: false,
    }));
  };

  const inviteUser = () => {
    if (onboardingTasksProgress?.isUserInvited) {
      navigateTo(NavigationRoute.INVITE_TAB);
    } else {
      setModalVisibility(ModalVisibility.ADD_USER);
    }
  };

  useEffect(() => {
    (async () => {
      const licenseCountResponse = await licenseCount();
      if (licenseCountResponse) {
        setLicenseCountObject(licenseCountResponse);
      }
    })();
  }, []);

  const getHeadingElement = (text:string) => (
    <div className={styles.taskHeader}>
      <div className={styles.customIcon} />
      <h1>{text}</h1>
    </div>
  );

  return (
    <div className={styles.onboardingContainer}>
      <Collapse accordion defaultActiveKey={['1']}>
        <Panel showArrow={false} header={getHeadingElement('View Content')} key="1">
          <li>
            <h2>
              Start a course yourself and/or
              {' '}
              <strong>
                conduct your own
                Soft Skills Gap Analysis.&nbsp;&nbsp;&nbsp;
              </strong>
              <span className={styles.newtext}>NEW</span>
            </h2>
            <button
              id="admin-overview-onboarding-switch-to-learner"
              type="button"
              onClick={() => navigateTo(NavigationRoute.LEARNER_PAGE)}
            >
              Switch to Learner
            </button>
          </li>
        </Panel>
        <Panel showArrow={false} header={getHeadingElement('Invite your team')} key="2">
          <li>
            <h2>
              Invite your teammates to join, understand their current skills, enrol to courses,
              build new skills and track their progress.
            </h2>
            <button
              id="admin-overview-onboarding-invite"
              type="button"
              onClick={inviteUser}
            >
              Invite
            </button>
          </li>
        </Panel>
        <Panel showArrow={false} header={getHeadingElement('Soft Skills Gap Analysis')} key="3">
          <li>
            <h2>
              Find out where your teams&apos; strengths and weaknesses lie to
              prioritise what to learn and courses to start with.
            </h2>
            <button
              id="admin-overview-onboarding-skill-gap-review"
              type="button"
              onClick={() => navigateTo(NavigationRoute.PERFORMANCE_TAB)}
            >
              Review
            </button>
          </li>
        </Panel>
        <Panel showArrow={false} header={getHeadingElement(companyInfo?.scormCloudAppID ? 'Upload, Hide or Assign Courses' : 'Hide / Assign Courses')} key="4">
          <li>
            <div className={styles.list}>
              { companyInfo?.scormCloudAppID
              && (
              <li>
                <strong>Upload </strong>
                custom course content for your Learners
              </li>
              )}
              <li>
                <strong>Hide </strong>
                courses to customise your course catalogue
              </li>
              <li>
                <strong>Assign </strong>
                courses directly to your Learners
              </li>
            </div>
            <button
              id="admin-overview-onboarding-view-growth-tools"
              type="button"
              onClick={() => navigateTo(NavigationRoute.TOOLS_TAB)}
            >
              View Tools
            </button>
          </li>
        </Panel>
      </Collapse>
      <AddUser
        isModalVisible={modalVisibility === ModalVisibility.ADD_USER}
        onOk={sendInvite}
        onCancel={() => setModalVisibility(ModalVisibility.DEFAULT)}
        setIsImportStatusModalVisible={setIsImportStatusModalVisible}
        identityProviderClient={identityProviderClient}
        passUploadedArrayFromFile={passUploadedArrayFromFile}
        companyInfo={companyInfo}
        licenseCountObject={licenseCountObject}
        domIDs={{
          GSuite: 'admin-overview-onboarding-invite-popup-gsuite',
          SendInvite: 'admin-overview-onboarding-invite-popup-send-invite',
          UploadCSV: 'admin-overview-onboarding-invite-popup-upload-csv',
          Invite: 'admin-overview-onboarding-google-contacts-popup-invite',
          DownloadSampleCSV: 'admin-overview-onboarding-invite-popup-download-sample-csv',
        }}
      />
      <ImportedUserProgress
        isImportStatusModalVisible={modalVisibility === ModalVisibility.IMPORTED_USER}
        onCancel={closeImportedUserProgressModal}
        fileDataArray={fileDataArray}
        jsonToCSV={jsonToCSV}
        domID="admin-overview-onboarding-csv-upload-progress-popup-download-failed-records"
      />
    </div>
  );
};

export default Onboarding;
