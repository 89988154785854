import React from 'react';
import PropTypes from 'prop-types';
import Overview from '../../components/Admin/Overview';
import { AdminProvider } from '../../context/Admin/AdminContext';
import Authenticate from '../../components/Identity/Authenticate';
import AuthorizationWrapper from '../../components/Admin/AuthorizationWrapper';

const OverviewPage = ({ identityProviderClient }) => (
  <AdminProvider>
    <AuthorizationWrapper>
      <Overview identityProviderClient={identityProviderClient} />
    </AuthorizationWrapper>
  </AdminProvider>
);
OverviewPage.propTypes = {
  identityProviderClient: PropTypes.objectOf(PropTypes.any).isRequired,
};
export default Authenticate(OverviewPage);
